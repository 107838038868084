@font-face {
  font-family: 'FeatureFont';
  src:  url('../fonts/feature-font/FeatureFont.eot?n5cy8w');
  src:  url('../fonts/feature-font/FeatureFont.eot?n5cy8w#iefix') format('embedded-opentype'),
    url('../fonts/feature-font/FeatureFont.ttf?n5cy8w') format('truetype'),
    url('../fonts/feature-font/FeatureFont.woff?n5cy8w') format('woff'),
    url('../fonts/feature-font/FeatureFont.svg?n5cy8w#FeatureFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ff {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FeatureFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-microwave:before {
  content: "\e900";
}
.ff-fridge:before {
  content: "\e901";
}
.ff-diswasher:before {
  content: "\e902";
}
.ff-dryer:before {
  content: "\e806";
}
.ff-washing-machine:before {
  content: "\e807";
}
.ff-oven:before {
  content: "\e808";
}
.ff-oven-o:before {
  content: "\e903";
}
.ff-coffee-machine:before {
  content: "\e904";
}
.ff-coffee-machine-o:before {
  content: "\e905";
}
.ff-coffee-italian:before {
  content: "\e911";
}
.ff-toaster:before {
  content: "\e906";
}
.ff-air-conditioner:before {
  content: "\e907";
}
.ff-iron:before {
  content: "\e908";
}
.ff-hairdresser:before {
  content: "\e909";
}
.ff-bed:before {
  content: "\e90a";
}
.ff-king-size-bed:before {
  content: "\e90b";
}
.ff-king-size-bed-o:before {
  content: "\e90c";
}
.ff-bed-sheets:before {
  content: "\e912";
}
.ff-bed-sofa:before {
  content: "\e913";
}
.ff-shower:before {
  content: "\e80f";
}
.ff-bath:before {
  content: "\e90d";
}
.ff-hottub:before {
  content: "\e80c";
}
.ff-safe:before {
  content: "\e809";
}
.ff-security:before {
  content: "\e811";
}
.ff-heating:before {
  content: "\e80a";
}
.ff-fireplace:before {
  content: "\e805";
}
.ff-tv-retro:before {
  content: "\e818";
}
.ff-tv-flatscreen:before {
  content: "\e817";
}
.ff-tv-flatscreen-o:before {
  content: "\e90e";
}
.ff-computer:before {
  content: "\e81c";
}
.ff-laptop:before {
  content: "\e81d";
}
.ff-printer:before {
  content: "\e820";
}
.ff-projector:before {
  content: "\e81b";
}
.ff-film:before {
  content: "\e86e";
}
.ff-discs:before {
  content: "\e871";
}
.ff-music:before {
  content: "\e86f";
}
.ff-wifi:before {
  content: "\e81a";
}
.ff-phone:before {
  content: "\e812";
}
.ff-bar-graph:before {
  content: "\e819";
}
.ff-craddle:before {
  content: "\e90f";
}
.ff-baby:before {
  content: "\e851";
}
.ff-diaper:before {
  content: "\e810";
}
.ff-home:before {
  content: "\e801";
}
.ff-balcony:before {
  content: "\e914";
}
.ff-terrace:before {
  content: "\e915";
}
.ff-sea-view:before {
  content: "\e916";
}
.ff-city:before {
  content: "\e803";
}
.ff-elevator:before {
  content: "\e816";
}
.ff-doorman:before {
  content: "\e910";
}
.ff-boat:before {
  content: "\e822";
}
.ff-bicycle:before {
  content: "\e823";
}
.ff-motorcycle:before {
  content: "\e824";
}
.ff-car:before {
  content: "\e825";
}
.ff-taxi:before {
  content: "\e826";
}
.ff-bus:before {
  content: "\e827";
}
.ff-train:before {
  content: "\e828";
}
.ff-gas-station:before {
  content: "\e829";
}
.ff-plane:before {
  content: "\e82a";
}
.ff-dog:before {
  content: "\e82d";
}
.ff-add:before {
  content: "\e82f";
}
.ff-block:before {
  content: "\e830";
}
.ff-no-smoking:before {
  content: "\e831";
}
.ff-info:before {
  content: "\e832";
}
.ff-parking:before {
  content: "\e834";
}
.ff-alert:before {
  content: "\e835";
}
.ff-star:before {
  content: "\e836";
}
.ff-smile:before {
  content: "\e837";
}
.ff-coffee:before {
  content: "\e839";
}
.ff-martini:before {
  content: "\e83a";
}
.ff-wine:before {
  content: "\e83b";
}
.ff-glass-cup:before {
  content: "\e83c";
}
.ff-beer:before {
  content: "\e83d";
}
.ff-burger:before {
  content: "\e83e";
}
.ff-hotdog:before {
  content: "\e83f";
}
.ff-french-fries:before {
  content: "\e840";
}
.ff-dining:before {
  content: "\e842";
}
.ff-videogame:before {
  content: "\e843";
}
.ff-eightball:before {
  content: "\e844";
}
.ff-letter-blocks:before {
  content: "\e845";
}
.ff-teddy-bear:before {
  content: "\e852";
}
.ff-puzzle:before {
  content: "\e846";
}
.ff-golf:before {
  content: "\e849";
}
.ff-beach:before {
  content: "\e84a";
}
.ff-theater:before {
  content: "\e84c";
}
.ff-gym:before {
  content: "\e813";
}
.ff-museum:before {
  content: "\e84d";
}
.ff-hiker:before {
  content: "\e84e";
}
.ff-meeting-table:before {
  content: "\e853";
}
.ff-flower:before {
  content: "\e85c";
}
.ff-leaf:before {
  content: "\e85b";
}
.ff-umbrella:before {
  content: "\e860";
}
.ff-calendar:before {
  content: "\e861";
}
.ff-credit-card:before {
  content: "\e862";
}
.ff-check-money:before {
  content: "\e863";
}
.ff-shopping-cart:before {
  content: "\e864";
}
.ff-bag-shopping:before {
  content: "\e865";
}
.ff-gift-present:before {
  content: "\e866";
}
.ff-takeout:before {
  content: "\e867";
}
.ff-clock:before {
  content: "\e86b";
}
.ff-clock-alarm:before {
  content: "\e86c";
}
.ff-lock:before {
  content: "\e874";
}
.ff-key:before {
  content: "\e875";
}
.ff-map:before {
  content: "\e877";
}
.ff-newspaper:before {
  content: "\e87c";
}
.ff-address-book:before {
  content: "\e800";
}
.ff-award-ribbon:before {
  content: "\e87f";
}
.ff-badge:before {
  content: "\e880";
}
.ff-battery:before {
  content: "\e881";
}
.ff-first-aid:before {
  content: "\e886";
}
.ff-king:before {
  content: "\e887";
}
.ff-earth:before {
  content: "\e889";
}
.ff-search:before {
  content: "\e88c";
}
.ff-comment:before {
  content: "\e88d";
}
.ff-tag:before {
  content: "\e88e";
}
.ff-squeezer:before {
  content: "\e917";
}
.ff-swimming-pool:before {
  content: "\e919";
}
.ff-amenities:before {
  content: "\e918";
}
.ff-tea-pot:before {
  content: "\e91a";
}

