/* GENERAL */
a span {
    text-transform: lowercase;
}
.green {
    color: #37C537;
}
.blue {
    color: #0078D7;
}
.gm-style-mtc { display: none; } /* Hide google options */
p strong {
    font-family: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
}
button.disabled {
    background-color: #bcbcbc !important;
    border: 1px solid #f0f0f0 !important;
}
.btn {
    text-align: center;
}
.btn.btn-green {
    background: #37C537;
    color: #ffffff;
    border: 1px solid #37C537;
}
.btn.btn-green:hover {
    background: #20DD20;
    border: 1px solid #20DD20;
}
.warning {
    color: #F4645F;
}
.help-block {
    margin-top: 2px;
    font-size: 12px;
}
.help-block.error {
    color: #a94442;
}
.help-block.error:before {
    content: '\f057';
    font-family: 'FontAwesome';
    margin-right: 5px;
}
.preamble.error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    padding: 10px;
}

/* HEADER */
#header {
    position: absolute;
    top: 0;
    background: rgba(5, 31, 73, 0.4);
    background: transparent;
    width: 100%;
    z-index: 9;
}
#header .booking-form span.select-box {
    width: auto;
}
#header .logo {
    z-index: 9999;
}
#header .header-social button {
    color: #808080;
    text-transform: uppercase;
}
#header .contact-right .header-menu .header-btn {
    width: 75px;
    color: #808080;
    text-transform: uppercase;
}

#header .btn.btn-gray
{
    background: #808080;
    color: #ffffff;
    border-color: #808080;
}
#header .btn.btn-gray:hover
{
    background: #333333;
    border-color: #333333;
}

#header .contact-right .header-booking .header-btn.btn-white,
#header .contact-right .header-menu .header-btn.btn-white,
#header .contact-right .header-social .header-btn.btn-white
{
    outline: none !important;
    background: #ffffff;
    color: #808080;
}

/* BANNER */
#banner {
    padding-bottom: 50%;
}
#banner .background-slider .owl-item
{
    background-position: center center;
    background-size: 100% 100%;
}
/* FOOTER */
#footer .footer-top .widget.widget-news ul li.address a:before { content: "\f041"; }
#footer .footer-top .widget.widget-news ul li.phone a:before { content: "\f10b"; }
#footer .footer-top .widget.widget-news ul li.phone2 a:before { content: "\f095"; }
#footer .footer-top .widget.widget-news ul li.email a:before { content: "\f003"; }

/* HOME */
.preamble {
    padding-left: 30px;
    margin-bottom: 15px;
}
.preamble h3 {
    text-align: left;
    border-left: 3px solid #333333;
    padding-left: 10px;
}
.preamble h3:after {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    margin-top: -5px;
    content: '';
}
.preamble p {
    text-align: left;
}

/* PROPERTY LISTING */
.header-section.listing {
    background: url(../images/header-listing2b.jpg) no-repeat center;
    background-size: cover;
    height: 300px;
    display: table;
    width: 100%;
}
.header-section.listing .container {
    vertical-align: middle;
    display: table-cell;
}
.header-section.listing .container .row {
    width: 1170px;
    margin: 0 auto;
}
@media (min-width: 320px) {
    .header-section.listing .container .row {
        width: 300px;
    }
}
@media (min-width: 480px) {
    .header-section.listing .container .row {
        width: 466px;
    }
}
@media (min-width: 768px) {
    .header-section.listing .container .row {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .header-section.listing .container .row {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .header-section.listing .container .row {
        width: 1170px;
    }
}
#listing, .news {
    padding: 50px 0 45px;
}
#listing .listing-content .listing-room-grid header {
    float: none;
}
#listing .listing-content .listing-room-grid header h5.title a {
    font-size: 21px;
}
#listing .listing-content .listing-room-grid .info {
    border-bottom: 1px solid #ededed;
    min-height: 170px;
    text-align: left;
    padding: 0 17px 17px;
}
#listing .listing-content .listing-room-grid .info p {
    line-height: 20px;
    min-height: 80px;
}
#listing .listing-content .listing-room-grid .info ul {
    padding: 0 0 0 17px;
    margin: 0;
}
.price {
    padding-top: 13px;
    margin-bottom: 9px;
}
.price span {
    color: #0088CC !important;
}
#listing .listing-content .minimum-price {
    margin-bottom: 20px;
    font-size: 13px;
    display: block;
    font-style: italic;
}
#listing .listing-facitilities p {
    line-height: 28px;
}
#listing .listing-content .listing-room-list .listing-room-content .pull-right
{
    text-align: right;
}
#listing .listing-content .listing-room-list .listing-room-content header .price {
    padding-top: 17px;
}
#listing .listing-content .listing-room-list .listing-room-content header .minimum-price {
    margin-bottom: 0;
}
#listing .owl-carousel .owl-item {
    background-color: #E5E5E5;
    min-height: 200px;
}
/* DATEPICKER */
#booking__calendar .ui-datepicker
{
    width: auto;
}
#booking__calendar {
    margin-bottom: 20px;
}
#booking__calendar td a,
#booking__calendar td span {
    font-size: 14px;
}
#booking__calendar .dp-highlight {
    background-color: #051F49!important;
    color: #fff!important;
    border: 1px solid #ffffff;
    font-weight: 300;
}
#booking__calendar .dp-highlight a,
#booking__calendar .dp-highlight span {
    color: #fff;
}
#booking__calendar .ui-datepicker-calendar th,
#booking__calendar .ui-datepicker-calendar td {
    border: 1px solid #ffffff;
}
#booking__calendar .ui-datepicker-calendar td,
#booking__calendar .ui-datepicker-calendar th {
    padding: 0;
}
#booking__calendar .ui-datepicker-calendar td a,
#booking__calendar .ui-datepicker-calendar td span,
#booking__calendar .ui-datepicker-calendar th span {
    position: relative;
    padding: .6em .5em;
    display: block;
    z-index: 1;
}
#booking__calendar td.arrival, #booking__calendar td.departure {
    position: relative;
    background-color: #A0BFCE!important;
    overflow: hidden;
}
#booking__calendar td.departure:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    height: 200%;
    background-color: #fff;
    top: -70%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 0;
}
#booking__calendar td.arrival:before {
    content: '';
    display: block;
    position: absolute;
    left: -50%;
    width: 100%;
    height: 200%;
    background-color: #fff;
    top: -70%;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    z-index: 0;
}
#booking__calendar td.arrival a,
#booking__calendar td.departure a,
#booking__calendar td.departure.ui-datepicker-current-day a.ui-state-highlight,
#booking__calendar td.arrival.ui-datepicker-current-day a.ui-state-highlight {
    color: #333333;
}
#booking__calendar .ui-datepicker-unselectable a,
#booking__calendar .ui-state-disabled .ui-state-default {
    color: #c7c7c7;
}
#booking__calendar .booked .ui-state-default {
    background-color: #A0BFCE!important;
    color: #ffffff;
}
#booking__calendar td.arrival.dp-highlight:before,
#booking__calendar td.departure.dp-highlight:before {
    background-color: #051F49!important;
}
#booking__calendar td.arrival a.ui-state-active,
#booking__calendar td.departure a.ui-state-active,
#booking__calendar td.departure.dp-highlight a.ui-state-highlight,
#booking__calendar td.arrival.dp-highlight a.ui-state-highlight,
#booking__calendar td.departure.dp-highlight a,
#booking__calendar td.arrival.dp-highlight a {
    color: #ffffff;
}
/* SHOW PROPERTY */
#room .room-wrapper {
    margin-top: -8%;
}
@media (max-width: 992px) {
    #room .room-wrapper .room-general ul li {
        display: block;
        padding-left: 0;
    }
    #room .room-wrapper .room-general ul li:after {
        content: '' !important;
        padding: 0 !important;
    }
}
#room .room-wrapper .room-content .room-about .description p {
    margin-bottom: 15px;
}
.room-about ul li {
    margin-bottom: 10px;
    float: left;
    width: 50%;
}
.room-about ul li:last-of-type {
    margin-bottom: 0;
}
.room-about ul li .circle {
    width: 30px;
    height: 30px;
    -moz-border-radius: 15px; 
    -webkit-border-radius: 15px; 
    border-radius: 15px;
    background-color: #333333;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
}
.room-about ul li .circle i {
    font-size: 17px;
    color: #fff;
    line-height: 30px;
}
.room-about ul li span {
    font-size: 15px;
    line-height: 30px;
    vertical-align: top;
    display: inline-block;
}
.reservation h5,
.reservation #makeBooking {
    display: none;
}
.reservation #makeBooking {
    margin-top: 10px;
}
/* Booking */
.sidebar-widget {
    background-color: #ffffff;
    margin-bottom: 30px;
}
.sidebar-widget-content {
    padding: 20px;
}
.sidebar-widget .general input,
.sidebar-widget .general textarea {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 10px 20px 10px 10px;
    border: 1px solid #CCCCCC;
}
.sidebar-widget .general textarea {
    resize: vertical;
}
.toggle-content .image-container img {
    width: 100%;
}
.toggle-title {
    position: relative;
    padding: 27px 30px;
    margin: 0;
    display: block;
    border-bottom: 1px solid #ededed;
}
.toggle-title span
{
    color: #0078D7;
}
.pay {
    font-family: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #CCCCCC;
    text-transform: uppercase;
    font-size: 13px;
    color: #333333;
}
.pay-ammount,
.pay-currency {
    color: #808080;
    font-size: 15px;
}
.pay.now {
    background-color: #0078D7;
    border: 1px solid #162E55;
}
.pay.now,
.pay.now .pay-ammount,
.pay.now .pay-currency {
    color: #ffffff;
}

#paymentForm .important-information aside
{
    padding: 20px 30px;
}
#paymentForm .important-information aside h5
{
    padding-top: 30px;
    border-top: 1px solid #EDEDED;
}
#paymentForm .important-information aside h5:first-of-type
{
    padding-top: 0;
    border: 0;
}
#paymentForm .default-form span {
    position: relative;
    display: block;
    margin-bottom: 20px;
}
#paymentForm .default-form span .fa {
    position: absolute;
    right: 25px;
    top: 18px;
    color: #808080;
    cursor: pointer;
}
#paymentForm .default-form ul.additional-filter-list
{
    margin-bottom: 10px;
}
#paymentForm .default-form ul.additional-filter-list li span
{
    margin-bottom: 5px;
}

#paymentForm .sidebar .sidebar-widget .general ul li span.help-block.error
{
    margin-top: -5px;
}

/**
 * ContactForm
 */

#contactForm textarea
{
    margin-bottom: 10px;
}

#contactForm .h5__msg 
{
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

/**
 * Services
 */

section.staff .staffer-profile-content p {
    margin-bottom: 0;
}

section.staff .hotel-staffers-list .staffer-profile {
    position: relative;
    margin-bottom: 30px;
    min-height: 194px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
section.staff .hotel-staffers-list .staffer-profile:last-child {
    margin-bottom: 0;
}

@media (max-width: 1199px)
{
    #listing .listing-content .listing-room-list .listing-room-content header {
        border-bottom: 0;
        margin: 0;
        padding: 0;
        height: 200px;
    }
    #listing .listing-content .listing-room-list .listing-room-content header .btn {
        margin-top: 40px !important;
    }
    #listing ul.tags li {
        display: block;
        padding: 0;
    }
    #listing ul.tags li:after {
        content: '' !important;
    }
    #listing .listing-content .listing-room-list .listing-room-content ul.tags li:before {
        font-family: 'FontAwesome';
        content: '\f0da' !important;
        margin-right: 5px;
        color: #808080;
    }
}

@media (max-width: 991px) {
  #listing .listing-room-content .listing-facitilities {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 990px) {
    .header-section.listing h3,
    .header-section.listing ul.breadcrumbs {
        float: none !important;
        display: block !important;
    }
    #listing .listing-content .listing-room-list .thumbnail {
        position: absolute;
        margin: 0;
    }
    #listing .listing-content .listing-room-list .listing-room-content {
        padding-left: 230px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .header-section.listing h3,
    .header-section.listing ul.breadcrumbs {
        float: none !important;
        display: block !important;
    }
    #listing .listing-content .listing-room-list .thumbnail {
        position: absolute;
        margin: 0;
    }    
    #listing .listing-content .listing-room-list .listing-room-content {
        padding-left: 220px;
    }
    #listing .listing-content .listing-room-list .listing-room-content .pull-left,
    #listing .listing-content .listing-room-list .listing-room-content .pull-right {
        float: none !important;
        text-align: left;
    }
    #listing .listing-content .listing-room-list .listing-room-content ul.tags li {
        display: block;
        padding: 0;
    }
    #listing .listing-content .listing-room-list .listing-room-content ul.tags li:before {
        font-family: 'FontAwesome';
        content: '\f0da' !important;
        margin-right: 5px;
        color: #808080;
    }
    #listing .listing-content .listing-room-list .listing-room-content ul.tags li:after {
        content: '' !important;
    }
    #listing .listing-content .listing-room-list .description.visible-xs
    {
        border-top: 1px solid #ededed;
        margin: 20px -15px 0;
        padding: 15px 15px 0;
    }
    #listing .listing-content .listing-room-list .btn.btn-gray-dark {
        display: none;
    }
    #listing .listing-content .listing-room-list:hover .btn.btn-gray-dark {
        display: block;
        margin-bottom: 10px;
    }
    #room .room-about ul li {
        float: none;
        width: 100%;
    }
}

@media (max-width: 480px) {
    #header {
        position: relative;
    }
    #header .logo {
        margin-bottom: 20px;
        float: none !important;   
    }
    #listing .listing-content .listing-room-list .listing-room-content ul.tags li:before {
        font-family: 'FontAwesome';
        content: '\f0da' !important;
        margin-right: 5px;
        color: #808080;
    }
    #listing .listing-content .listing-room-list .listing-room-content .pull-left {
        margin-bottom: 15px;
    }    
    #listing .listing-content .listing-room-list .listing-room-content .pull-right {
        text-align: left;
        border-top: 1px solid #ededed;
        margin: 5px -15px 0;
        padding: 0 15px;
    }
    #listing .listing-content .listing-room-list .description.visible-xs
    {
        border-top: 1px solid #ededed;
        margin: 40px -15px 0;
        padding: 15px 15px 0;
    }
    #listing .listing-content .listing-room-list .btn.btn-gray-dark {
        display: none;
    }
    #listing .listing-content .listing-room-list:hover .btn.btn-gray-dark {
        display: block;
        margin-bottom: 10px;
    }
    #room .room-about ul li {
        float: none;
        width: 100%;
    }
}